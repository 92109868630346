exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admins-js": () => import("./../../../src/pages/admins.js" /* webpackChunkName: "component---src-pages-admins-js" */),
  "component---src-pages-alerts-js": () => import("./../../../src/pages/alerts.js" /* webpackChunkName: "component---src-pages-alerts-js" */),
  "component---src-pages-coupons-js": () => import("./../../../src/pages/coupons.js" /* webpackChunkName: "component---src-pages-coupons-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maps-js": () => import("./../../../src/pages/maps.js" /* webpackChunkName: "component---src-pages-maps-js" */),
  "component---src-pages-messages-js": () => import("./../../../src/pages/messages.js" /* webpackChunkName: "component---src-pages-messages-js" */),
  "component---src-pages-passkeys-js": () => import("./../../../src/pages/passkeys.js" /* webpackChunkName: "component---src-pages-passkeys-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-reservations-calendar-js": () => import("./../../../src/pages/reservationsCalendar.js" /* webpackChunkName: "component---src-pages-reservations-calendar-js" */),
  "component---src-pages-reservations-js": () => import("./../../../src/pages/reservations.js" /* webpackChunkName: "component---src-pages-reservations-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-rides-js": () => import("./../../../src/pages/rides.js" /* webpackChunkName: "component---src-pages-rides-js" */),
  "component---src-pages-users-js": () => import("./../../../src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */),
  "component---src-pages-vehicles-js": () => import("./../../../src/pages/vehicles.js" /* webpackChunkName: "component---src-pages-vehicles-js" */),
  "component---src-pages-waivers-js": () => import("./../../../src/pages/waivers.js" /* webpackChunkName: "component---src-pages-waivers-js" */)
}

