import * as api from '../api';

const GET_ALERTS = 'operators/GET_ALERTS';


const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALERTS:
      return action.data;
    default:
      return state;
  }
}

export const getAlerts = (jwt, fleetId) => {
  return (dispatch) => {
    return api
      .getAlerts(jwt, fleetId)
      .then((response) => {
        dispatch({ type: GET_ALERTS, data: response });
      })
      .catch((error) => {
        console.error('Error in getAlerts action creator:', error);
        throw error;
      });
  };
};

